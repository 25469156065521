<script>
/* COMPOSANTS */
import AkDropdown from '@components/v2/input/AkDropdown.vue';
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import sidebarMixin from '@/mixins/sidebarMixin';
import commercialAutocompleteMixin from '@mixins/autocomplete/commercialAutocompleteMixin';

export default {
  components: {
    AkButtonFooterSidebarFilter,
    AkInputText,
    AkDropdown,
    AkAutocomplete,
  },
  mixins: [sidebarMixin, commercialAutocompleteMixin],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  methods: {
    itemSelect() {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.commercialAutocomplete) this.$refs.commercialAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <AkInputText
      v-model="currentValue.contactCompanyName"
      :placeholder="$t('contact.companyName')"
      @change="itemSelect" />
    <AkAutocomplete
      ref="commercialAutocomplete"
      v-model="currentValue.id"
      :init-method="autocompleteCommercialById"
      :placeholder="$t('commercial.representant')"
      :search-method="autocompleteCommercial"
      inline
      option-label="display"
      option-value="id"
      @item-select="itemSelect" />
    <AkInputText
      v-model="currentValue.email"
      :placeholder="$t('client.email')"
      type="email"
      @valueChange="itemSelect" />
    <AkInputText v-model="currentValue.phone" :placeholder="$t('contact.phone')" type="tel" @valueChange="itemSelect" />
    <AkDropdown
      v-model="currentValue.userEnabled"
      :label="$t('status')"
      :options="[
        {label: $t('commercial.all'), value: null},
        {label: $t('actives'), value: true},
        {label: $t('inactives'), value: false},
      ]"
      inline
      option-label="label"
      option-value="value"
      @value-change="itemSelect" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search()" @reset="reset()" />
  </form>
</template>
