<script>
/* COMPOSANTS */
import RecapitulatifFacturationAllInclusiveList from '@views/settings/ParametrageCommission/ParametrageCommissionAllInclusiveList';
import RecapitulatifFacturationOnlineList from '@views/settings/ParametrageCommission/ParametrageCommissionOnlineList';

/* MIXINS */
import recapitulatifFacturationTabMixin from '@mixins/tab/parametrageCommissionTabMixin';
import GaView from '@components/v2/layout/GaView.vue';
import {useTabStore} from '@store/tabStore';
import {PermissionMap} from '@mixins/const/permissionConst';

/* SERVICES */

export default {
  components: {
    GaView,
    RecapitulatifFacturationOnlineList,
    RecapitulatifFacturationAllInclusiveList,
  },
  mixins: [recapitulatifFacturationTabMixin],
  setup() {},
  metaInfo() {
    return {
      title: this.$t('settings.recapitulatif_facturation'),
    };
  },
  data() {
    return {
      tabSelected: undefined,
    };
  },
  computed: {
    activeTab() {
      if (this.tabSelected) return this.tabSelected;
      return 'offerAllInclusive';
    },
  },
  mounted() {
    this.tabSelected = useTabStore().getTabActive('ParametrageCommissionPage', 'offerAllInclusive');
  },
  methods: {
    displayTab(tab) {
      console.log(tab);
      useTabStore().setTabActive('ParametrageCommissionPage', tab.id);
      this.tabSelected = tab.id;
    },
  },
};
</script>

<template>
  <GaView>
    <template #content>
      <RecapitulatifFacturationOnlineList v-if="isOnline" @tab="this.displayTab" :tabs="tabs" />
      <RecapitulatifFacturationAllInclusiveList v-if="isAllIncluse" @tab="this.displayTab" :tabs="tabs" />
    </template>
  </GaView>
  <!--
          <AkLoader ref="loader" :message="$t('currently_loading')" :scale="1.5">
            <template #content>
              <GaTabView :firstTab="getFirstTab()" :title="$t('settings.recapitulatif_facturation')" :tabs="tabs">
                <template #offerAllInclusive>
                  <RecapitulatifFacturationAllInclusiveList />
                </template>
                <template #offerOnline>
                  <RecapitulatifFacturationOnlineList />
                </template>
              </GaTabView>
            </template>
          </AkLoader>
        </template> </GaListView></template
  ></GaView>
  -->
</template>
