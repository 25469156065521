import Api from '@/services/api';
import {removeEmptyParams, transformDownloadResponse} from '@utils';
import contentDisposition from 'content-disposition';

export default {
  accommodations(filter) {
    return Api()
      .get('/accommodations', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  accommodationThumbnails(filter) {
    return Api()
      .get('/accommodations/thumbnails', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  autocomplete(filter) {
    return Api()
      .get('/accommodations/autocomplete', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  accommodationsPagination(filter) {
    return Api()
      .get('/accommodations/pagination', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach(this.formatRes);
        let response = {};
        response.data = res.data;
        response.totalItems = parseInt(res.headers['x-pagination-total']);
        return response;
      });
  },
  count(filter) {
    return Api()
      .get('/accommodation/count', {params: removeEmptyParams(filter)})
      .then((res) => res.data);
  },
  accommodation(accommodationId) {
    return Api()
      .get('/accommodation/' + accommodationId)
      .then((res) => this.formatRes(res.data));
  },

  accommodationDetails(accommodationId) {
    return Api()
      .get('/accommodation/' + accommodationId + '/details')
      .then((res) => this.formatRes(res.data));
  },

  accommodationDisplay(accommodationId) {
    return Api()
      .get('/accommodation/' + accommodationId + '/display')
      .then((res) => this.formatRes(res.data));
  },
  accommodationFics(accommodationId) {
    return Api()
      .get('/accommodation/' + accommodationId + '/fics')
      .then((res) => res.data);
  },
  accommodationPicture(accommodationId) {
    return Api()
      .get('/accommodation/' + accommodationId + '/picture')
      .then((res) => res.data);
  },
  accommodationAmenities(accommodationId) {
    return Api()
      .get('/accommodation/' + accommodationId + '/amenities')
      .then((res) => res.data);
  },
  accommodationAnnonces(accommodationId) {
    return Api()
      .get('/accommodation/' + accommodationId + '/annonces')
      .then((res) => res.data);
  },
  accommodationPmr(accommodationId) {
    return Api()
      .get('/accommodation/' + accommodationId + '/pmr')
      .then((res) => res.data);
  },
  create(accommodation) {
    return Api()
      .post('/accommodation', accommodation)
      .then((res) => this.formatRes(res.data));
  },
  createAmenity(accommodationId, amenity) {
    return Api()
      .post('/accommodation/' + accommodationId + '/amenity', amenity)
      .then((res) => this.formatRes(res.data));
  },
  update(accommodation) {
    return Api()
      .put('/accommodation/' + accommodation.id, accommodation)
      .then((res) => this.formatRes(res.data));
  },
  enabled(accommodation) {
    return Api().put('/accommodation/' + accommodation.id + '/enabled', accommodation);
  },
  updateFromDetailsPage(dto) {
    return Api()
      .put('/accommodation/' + dto.accommodation.id + '/details', dto)
      .then((res) => this.formatRes(res.data));
  },
  synchronize(id) {
    return Api()
      .post('/accommodation/' + id + '/synchronize')
      .then((res) => this.formatRes(res.data));
  },
  publish(accommodation) {
    return Api()
      .post('/accommodation/' + accommodation.id + '/publish', accommodation)
      .then((res) => this.formatRes(res.data));
  },
  updatePictureFavorite(accommodationId, ficId) {
    return Api()
      .put('/accommodation/' + accommodationId + '/picturefavorite', {itemId: ficId})
      .then((res) => this.formatRes(res.data));
  },
  updateAccommodationDescriptions(desc, accommodationId) {
    return Api()
      .put('/accommodation/' + accommodationId + '/descriptions', desc)
      .then((res) => this.formatRes(res.data));
  },
  delete(accommodation) {
    return Api()
      .delete('/accommodation/' + accommodation.id)
      .then(() => accommodation);
  },
  deleteAmenity(accommodationId, amenityId) {
    return Api()
      .delete('/accommodation/' + accommodationId + '/amenity/' + amenityId)
      .then((res) => this.formatRes(res.data));
  },
  resume(filter) {
    return Api()
      .get('/accommodation/resume', {params: removeEmptyParams(filter)})
      .then((res) => res.data);
  },
  exportAccommodations(filter) {
    return Api()
      .get('/accommodations/export', {
        responseType: 'blob',
        transformResponse: [transformDownloadResponse],
        params: removeEmptyParams(filter),
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
      });
  },
  planning(filter) {
    return Api()
      .get('/accommodations/planning', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },
  planningCount(filter) {
    return Api()
      .get('/accommodations/planning/count', {params: removeEmptyParams(filter)})
      .then((res) => {
        return res.data;
      });
  },

  // STAYRATES

  stayrates(accommodationId) {
    return Api()
      .get('/accommodation/' + accommodationId + '/stayrates')
      .then((res) => this.formatRes(res.data));
  },

  updateStayrates(accommodationId, accommodationStayrates) {
    return Api()
      .put('/accommodation/' + accommodationId + '/stayrates', accommodationStayrates)
      .then((res) => this.formatRes(res.data));
  },

  formatRes(e) {
    if (e.unavailabilityList) {
      for (let item of e.unavailabilityList) {
        if (item.start) item.start = new Date(item.start);
        if (item.end) item.end = new Date(item.end);
      }
    }
    return e;
  },
};
