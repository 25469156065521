<script>
import useVuelidate from '@vuelidate/core';
import {required, helpers} from '@vuelidate/validators';

import {isValidPhoneNumber} from 'libphonenumber-js';

/* COMPOSANTS */
import AkFormSubmitted from '@components/general/AkFormSubmitted';
import GaView from '@components/v2/layout/GaView';
import AkDropdown from '@components/v2/input/AkDropdown';
import AkInputMoney from '@components/v2/input/AkInputMoney';
import AkInputText from '@components/v2/input/AkInputText';
import AkAddressFull from '@components/v2/input/AkAddressFull';
import AkButtonValidation from '@components/v2/general/AkButtonValidation.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import messageMixin from '@mixins/messageMixin';
import backMixin from '@mixins/backMixin';
import civilitiesConst from '@mixins/const/civilitiesConst';
import formatMixin from '@mixins/formatMixin';
import loaderMixin from '@mixins/loaderMixin';

/* SERVICES */
import commercialService from '@services/commercialService';
import itemLabelService from '@services/itemLabelService';

const phoneValidator = (value) => !value || isValidPhoneNumber(value);

export default {
  components: {
    AkButtonValidation,
    AkAddressFull,
    AkInputText,
    AkInputMoney,
    AkDropdown,
    GaView,
    AkFormSubmitted,
  },
  mixins: [permissionsMixin, messageMixin, backMixin, civilitiesConst, formatMixin, loaderMixin],
  setup() {
    return {v$: useVuelidate()};
  },
  metaInfo() {
    return {
      title: 'commercial.update',
    };
  },
  validations() {
    return {
      commercial: {
        contact: {
          companyName: {required},
          formeJuridiqueId: {required},
          raisonSociale: {required},
          phone: {
            required,
            phoneValidator: helpers.withMessage(
              'Vérifier le format du numéro de téléphone (Doit commencer par +33)',
              phoneValidator,
            ),
          },
          civility: {required},
          lastName: {required},
          firstName: {required},
        },
      },
    };
  },
  data() {
    return {
      submitted: false,
      listFormeJuridique: [],
      commercial: {
        contact: {
          companyName: null,
          formeJuridiqueId: null,
          raisonSociale: null,
          capital: null,
          address: null,
          complement: null,
          postalCode: null,
          city: null,
          state: null,
          country: null,
          email: null,
          phone: null,
          villeRcs: null,
          siret: null,
          civility: null,
          lastName: null,
          firstName: null,
          function: null,
        },
      },
    };
  },
  mounted() {
    this.initGoBack();
    this.showTotalLoaderGuestadom();

    let p1 = commercialService.commercial(this.$route.params.commercialId);
    p1.then((data) => {
      this.commercial = data;
    });

    let p2 = itemLabelService.itemLabels({type: 'FORME_JURIDIQUE'});
    p2.then((data) => {
      this.listFormeJuridique = data;
    });

    Promise.all([p1, p2]).finally(() => this.hideLoader());
  },
  methods: {
    updateCommercial() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) {
        this.$refs.btnUpdate.init();
        this.addError(this.$t('error.missing_fields'));
        return;
      }

      commercialService
        .update(this.commercial)
        .then(() => {
          this.success(this.$t('commercial.updated'));
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.submitted = false;
          this.$refs.btnUpdate.init();
        });
    },
  },
  computed: {
    isLoadingFormeJuridique() {
      return this.listFormeJuridique === undefined;
    },
  },
};
</script>

<template>
  <GaView details>
    <template #content>
      <AkFormSubmitted
        id="update-commercial-form"
        :submitted="submitted"
        class="flex flex-col md:gap-4 gap-2"
        reference="form">
        <!-- Formulaire contact -->

        <span class="title-border text-dark text-xl font-bold mb-3">Contact</span>
        <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 md:gap-4 gap-2">
          <AkDropdown
            v-model="commercial.contact.formeJuridiqueId"
            :label="$t('contact.formeJuridique')"
            :loading="isLoadingFormeJuridique"
            :options="this.listFormeJuridique"
            :validator="v$.commercial.contact.formeJuridiqueId"
            optionLabel="label"
            optionValue="id" />
          <AkInputText
            v-model="commercial.contact.raisonSociale"
            :formatter="toUppercase"
            :label="$t('contact.raisonSociale')"
            :validator="v$.commercial.contact.raisonSociale" />
          <AkInputText
            v-model="commercial.contact.companyName"
            :label="$t('contact.companyName')"
            :validator="v$.commercial.contact.companyName" />
          <AkInputMoney v-model="commercial.contact.capital" class-name="col-3" label="Capital (€)" />
        </div>

        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-2">
          <AkInputText v-model="commercial.contact.villeRcs" :label="$t('contact.villeRcs')" :formatter="toUppercase" />
          <AkInputText v-model="commercial.contact.siret" :label="$t('contact.siret')" />
        </div>

        <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 md:gap-4 gap-2">
          <AkDropdown
            v-model="commercial.contact.civility"
            :label="$t('contact.civilityManager')"
            :options="this.getCivilitiesConstForProspect()"
            :validator="v$.commercial.contact.civility" />
          <AkInputText
            v-model="commercial.contact.lastName"
            :label="$t('contact.lastNameManager')"
            :formatter="toUppercase"
            :validator="v$.commercial.contact.lastName" />
          <AkInputText
            v-model="commercial.contact.firstName"
            :label="$t('contact.firstNameManager')"
            :validator="v$.commercial.contact.firstName" />
          <AkInputText v-model="commercial.contact.function" :label="$t('contact.functionManager')" />
        </div>

        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-2">
          <AkInputText
            v-model="commercial.contact.email"
            :label="$t('contact.email')"
            disabled
            icon="ga-icon ga-email" />
          <AkInputText
            v-model="commercial.contact.phone"
            :label="$t('contact.phone')"
            :validator="v$.commercial.contact.phone"
            icon="ga-icon ga-call" />
        </div>

        <!-- Formulaire adresse -->

        <span class="title-border text-dark text-xl font-bold mb-3">{{ $t('client.title_address') }}</span>
        <AkAddressFull
          v-model="commercial.contact"
          :always-display-map="false"
          :can-change-position="false"
          :label="$t('contact.address1Manager')"
          :validator="v$.commercial" />

        <div class="flex justify-end mt-2">
          <AkButtonValidation
            ref="btnUpdate"
            :label="$t('update')"
            class="btn-primary-gradient"
            @action="updateCommercial" />
        </div>
      </AkFormSubmitted>
    </template>
  </GaView>
</template>
