<script>
/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText.vue';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import AkCalendar from '@components/v2/input/AkCalendar.vue';
import MultiSelect from 'primevue/multiselect';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import mandatStatusFilterConst from '@mixins/const/mandatStatusFilterConst';
import permissionsMixin from '@mixins/permissionsMixin';
import companyMixin from '@mixins/companyMixin';
import accommodationAutocompleteMixin from '@mixins/autocomplete/accommodationAutocompleteMixin';
import mandatTypeConst from '@mixins/const/mandatTypeConst';
import prospectOfferConst from '@mixins/const/prospectOfferConst';
import itemLabelService from '@services/itemLabelService';

/* SERVICES */

export default {
  components: {
    AkButtonFooterSidebarFilter,
    AkCalendar,
    AkAutocomplete,
    AkInputText,
    MultiSelect,
  },
  mixins: [
    utilsMixin,
    sidebarMixin,
    mandatStatusFilterConst,
    permissionsMixin,
    companyMixin,
    accommodationAutocompleteMixin,
    mandatTypeConst,
    prospectOfferConst,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      mandatMarcheList: undefined,
    };
  },
  mounted() {
    let p5 = itemLabelService.itemLabels({type: 'MARCHE'});
    p5.then((data) => {
      this.mandatMarcheList = data;
    });
  },
  methods: {
    itemSelect() {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.accommodationAutocomplete) this.$refs.accommodationAutocomplete.reset();
      this.callback[1]();
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },

    prospectOfferList() {
      return this.prospectOfferConst.slice(0, -1);
    },
    mandatTypeList() {
      return this.mandatTypeConst.slice(0, -1);
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <MultiSelect
      v-if="this.isGas()"
      v-model="currentValue.companyIds"
      :loading="this.loadingCompanies"
      :options="this.companies"
      :placeholder="$t('employee.company')"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      option-label="name"
      option-value="id"
      @change="itemSelect" />
    <AkInputText
      v-model="currentValue.ownerName"
      :placeholder="$t('interlocutor.proprietaire')"
      @valueChange="itemSelect" />
    <AkAutocomplete
      ref="accommodationAutocomplete"
      v-model="currentValue.accommodationId"
      :init-method="this.autocompleteAccommodationById"
      :inline="true"
      :placeholder="$t('accommodation_title')"
      :search-label="this.autocompleteAccommodationQuery"
      :search-method="this.autocompleteAccommodation"
      option-label="display"
      option-value="id"
      @item-select="itemSelect" />
    <AkInputText v-model="currentValue.mandatNum" :placeholder="$t('mandat.details')" @valueChange="itemSelect" />
    <AkCalendar
      v-model="currentValue.dateStartPeriod"
      :placeholder="$t('mandat.dateStartPeriod')"
      date-format="dd/mm/yy"
      selection="range"
      @valueChange="itemSelect" />
    <AkCalendar
      v-model="currentValue.dateEndPeriod"
      :placeholder="$t('mandat.dateEndPeriod')"
      date-format="dd/mm/yy"
      selection="range"
      @valueChange="itemSelect" />

    <!-- Offre du mandat -->
    <MultiSelect
      v-model="currentValue.offers"
      :options="this.prospectOfferList"
      :placeholder="$t('mandat.offer')"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      option-label="label"
      option-value="value"
      @change="itemSelect" />

    <!-- Type de mandat -->
    <MultiSelect
      v-model="currentValue.types"
      :options="this.mandatTypeList"
      :placeholder="$t('mandat.type')"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      option-label="label"
      option-value="value"
      @change="itemSelect" />
    <!-- Marche -->
    <MultiSelect
      v-model="currentValue.marchesId"
      :options="mandatMarcheList"
      :loading="mandatMarcheList === undefined"
      :placeholder="$t('item_label.marche')"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      option-label="label"
      option-value="id"
      @change="itemSelect" />
    <!-- Statut -->
    <MultiSelect
      v-model="currentValue.statuses"
      :options="mandatStatusFilterConst"
      :placeholder="$t('mandat.status')"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      option-label="label"
      option-value="value"
      @change="itemSelect" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search()" @reset="reset()" />
  </form>
</template>
