<script>
/* COMPONENTS */
import GaContentView from '@components/v2/layout/GaContentView.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkInputFilter from '@components/v2/filter/AkInputFilter';
import AkListFilter from '@components/v2/filter/AkListFilter';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import AkInputSwitch from '@components/v2/input/AkInputSwitch.vue';

/* MIXINS */
import messageMixin from '@mixins/messageMixin';
import utilsMixin from '@mixins/utilsMixin';
import companyMixin from '@mixins/companyMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import filterListMixin from '@mixins/filterListMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import ownerTypesConst from '@mixins/const/ownerTypesConst';
import prospectOfferConst from '@mixins/const/prospectOfferConst';

/* SERVICES */
import ownerService from '@services/ownerService';

export default {
  components: {
    GaContentView,
    AkDatatablePagination,
    GaListView,
    AkInputFilter,
    AkListFilter,
    AkAllFilter,
    AkInputSwitch,
  },
  mixins: [
    permissionsMixin,
    messageMixin,
    utilsMixin,
    companyMixin,
    filterListMixin,
    sidebarMixin,
    ownerTypesConst,
    prospectOfferConst,
  ],
  data() {
    return {
      commercialId: undefined,
      list: [],
      totalItems: 0,
      loading: true,
      filter: {
        ownerStatus: 'PROSPECT',
        sort: ['id:DESC'],
      },
    };
  },
  mounted() {
    this.commercialId = this.$route.params.commercialId;
  },
  computed: {},
  methods: {
    // Prospects
    search() {
      this.loading = true;
      this.filter.commercialId = this.commercialId;
      ownerService.owners(this.filter).then((data) => {
        this.list = data;
        this.loadFieldsForSort();
        this.loading = false;
      });
    },
    loadFieldsForSort() {
      for (let owner of this.list) {
        if (owner.contactList.length > 0) {
          let contact = owner.contactList[0];
          owner.contactPhone = contact.phone;
          owner.contactEmail = contact.email;
          owner.contactDisplay = contact.display;
        }
      }
    },

    // FILTER

    openFilterPanel() {
      this.toggleFilterTitle(
        'GaProspectFilterList',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {
        ownerStatus: 'PROSPECT',
        sort: ['id:DESC'],
      };
      this.toggleFilterTitle(
        'GaProspectFilterList',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },

    // SORT

    sortOwnerTypeField({ownerType}) {
      return this.ownerTypesConstAlphabetical.findIndex(({value}) => value === ownerType);
    },
  },
};
</script>

<template>
  <GaContentView>
    <template #content>
      <span class="title-border">{{ $t('prospect.list') }}</span>
      <GaListView>
        <template #filter>
          <AkListFilter
            v-if="this.isGas()"
            v-model="this.filter.companyIds"
            :items="this.companies"
            :label="$t('company.one')"
            multi-selection
            display-filter
            direction="right"
            @search="this.search" />
          <AkInputFilter @search="search" v-model="filter.ownerName" :label="$t('interlocutor.prospect')" />
          <AkInputFilter @search="search" v-model="filter.email" :label="$t('contact.email')" />
          <AkAllFilter @open="this.openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination :loading="loading" :value="list" :lazy="false" @search="this.search">
            <template #empty>
              {{ $t('prospect.empty') }}
            </template>

            <Column
              :header="$t('prospect.ownerType')"
              :sortable="true"
              :sort-field="sortOwnerTypeField"
              field="ownerType"
              style="min-width: 150px; width: 150px">
              <template #body="slotProps">
                {{ displayForOwnerTypeConst(slotProps.data.ownerType) }}
              </template>
            </Column>

            <Column :header="$t('prospect.offer')" style="width: 100px" :sortable="true" field="offer">
              <template #body="slotProps">
                {{ displayForProspectOfferConst(slotProps.data.offer) }}
              </template>
            </Column>

            <Column
              :header="$t('interlocutor.prospect')"
              style="min-width: 150px; width: 200px"
              :sortable="true"
              field="contactDisplay">
              <template #body="slotProps">
                <router-link :to="{name: 'prospectPage', params: {ownerId: slotProps.data.id}}">
                  {{ slotProps.data.contactDisplay }}
                </router-link>
              </template>
            </Column>

            <Column :header="$t('contact.email')" style="width: 200px" :sortable="true" field="contactEmail">
              <template #body="slotProps">
                {{ slotProps.data.contactEmail }}
              </template>
            </Column>

            <Column
              :header="$t('contact.phone')"
              style="min-width: 125px; width: 150px"
              :sortable="true"
              field="contactPhone">
              <template #body="slotProps">
                {{ slotProps.data.contactPhone }}
              </template>
            </Column>

            <Column
              :header="$t('user.enabled')"
              :sortable="true"
              body-style="text-align: center; overflow: visible"
              field="userEnabled"
              header-style=" width: 100px; text-align: center">
              <template #body="slotProps">
                <AkInputSwitch
                  validationPopup
                  :nameOnPopup="slotProps.data.contactDisplay"
                  disabled
                  v-model="slotProps.data.userEnabled" />
              </template>
            </Column>

            <Column header-style=" width: 50px;" header=" ">
              <template #body="slotProps">
                <div class="flex items-center gap-2">
                  <router-link :to="{name: 'prospectPage', params: {ownerId: slotProps.data.id}}">
                    <i class="ga-icon ga-external text-2xl text-primary" />
                  </router-link>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>
  </GaContentView>
</template>
