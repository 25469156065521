<script>
/* COMPOSANTS */
import ParametrageCommissionForm from './form/ParametrageCommissionForm';
import AkDialog from '@components/general/AkDialog';

/* MIXINS */
import loaderMixin from '@mixins/loaderMixin';
import randomRef from '@mixins/randomRef';
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import messageMixin from '@mixins/messageMixin';

/* SERVICES */
import comptaCommissionService from '@services/comptaCommissionService';
import GaListTabView from '@components/v2/layout/GaListTabView.vue';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
import {useCompanyStore} from '@store/companyStore';
import AkListFilter from '@components/v2/filter/AkListFilter.vue';
import companyMixin from '@mixins/companyMixin';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';

export default {
  props: {
    tabs: {
      type: Array,
      required: false,
      default: () => {
        return undefined;
      },
    },
  },
  emits: ['tab'],
  components: {
    AkButtonAction,
    AkListFilter,
    AkDatatablePagination,
    GaListTabView,
    ParametrageCommissionForm,
    AkDialog,
  },
  mixins: [loaderMixin, randomRef, companyMixin, permissionsMixin, utilsMixin, sidebarMixin, messageMixin],
  metaInfo() {
    return {
      title: 'settings.recapitulatif_facturation',
    };
  },
  data() {
    return {
      loading: true,
      filter: {},
      list: [],
      companies: [],
      item: {},
    };
  },
  mounted() {},
  methods: {
    clickSearch() {
      this.list = undefined;
      if (!this.filter.companyId) {
        this.addError(this.$t('recapitulatif_facturation.error_empty_filter_param'));
        return;
      }
      this.loading = true;
      comptaCommissionService
        .comptaCommissions({offer: 'ONLINE', companyId: this.filter.companyId})
        .then((data) => {
          this.list = data;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    create() {
      if (!this.$refs.formCreate.validateForm()) return;
      this.showTotalLoader();
      comptaCommissionService
        .create(this.item)
        .then((data) => {
          this.$refs.dialogCreate.hide();
          this.success(this.$t('recapitulatif_facturation.added'));
          this.clickSearch();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.$refs.dialogCreate.error(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    update() {
      if (!this.$refs.formUpdate.validateForm()) return;
      this.showTotalLoader();
      comptaCommissionService
        .update(this.item)
        .then((data) => {
          this.$refs.dialogUpdate.hide();
          this.success(this.$t('recapitulatif_facturation.updated'));
          this.clickSearch();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.$refs.dialogUpdate.error(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    delete() {
      this.showTotalLoader();
      comptaCommissionService
        .delete(this.item)
        .then((data) => {
          this.success(this.$t('recapitulatif_facturation.deleted'));
          this.clickSearch();
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },

    openCreateDialog() {
      this.item = {...this.list[0]};
      this.item.offer = 'ONLINE';
      this.item.companyId = this.filter.companyId;
      this.item.date = new Date();
      this.$refs.dialogCreate.show();
    },
    openDetailsDialog(data) {
      this.item = {...data};
      this.$refs.dialogDetails.show();
    },
    openUpdateDialog(data) {
      this.item = {...data};
      this.$refs.dialogUpdate.show();
    },
    openDeleteDialog(data) {
      this.item = {...data};
      this.$refs.dialogDelete.show();
    },

    openFilterPanel() {
      this.toggleFilterTitle(
        'GaParametrageCommissionFilter',
        this.$t('filter_all'),
        this.filter,
        this.clickSearch,
        this.resetFilter,
      );
    },
    resetFilter() {
      this.filter = {};
      this.toggleFilter('GaParametrageCommissionFilter', this.filter, this.refresh, this.resetFilter);
    },

    findCompanyDisplay(companyId) {
      let companies = useCompanyStore().companies;
      if (!companies) return null;
      let company = companies.find((c) => c.id === companyId);
      return company ? company.name : null;
    },
    findDisplay(item) {
      return this.findCompanyDisplay(item.companyId) + ' - ' + this.formatDateIfNeeded(item.date);
    },
    isCurrent(item) {
      return item && item.state == 'CURRENT';
    },
    isFuture(item) {
      return item && item.state == 'FUTURE';
    },
  },
  computed: {
    currentItem() {
      return this.list ? this.list.find(this.isCurrent) : null;
    },
    currentDisplay() {
      return this.currentItem ? this.findDisplay(this.currentItem) : '';
    },
  },
};
</script>

<template>
  <GaListTabView :tabs="tabs" currentTab="offerOnline" @tab="this.$emit('tab', $event)">
    <template #action>
      <AkButtonAction
        little
        v-if="this.filter.companyId"
        @click="openCreateDialog()"
        :label="$t('recapitulatif_facturation.add')">
      </AkButtonAction>
    </template>

    <template #filter>
      <AkListFilter
        :multi-selection="false"
        v-model="this.filter.companyId"
        :items="this.companies"
        :display-filter="true"
        @search="this.clickSearch"
        :label="$t('employee.company')"
        direction="right" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4" v-if="currentItem">
        <ParametrageCommissionForm v-if="currentItem" v-model="currentItem" :inputDisabled="true" />
        <span v-if="currentItem" class="title-border"> Historique des grilles tarifaires </span>
        <AkDatatablePagination :lazy="false" :value="list" :loading="loading">
          <template #empty>
            {{ $t('recapitulatif_facturation.empty') }}
          </template>

          <Column :header="$t('recapitulatif_facturation.date')" field="date" :sortable="true">
            <template #body="slotProps">
              <span>{{ this.formatDateIfNeeded(slotProps.data.date) }}</span>
            </template>
          </Column>

          <Column body-style="text-align: center; overflow: visible" style="width: 100px">
            <template #body="slotProps">
              <div class="flex justify-end items-center gap-2">
                <span class="cursor-pointer" @click="openDetailsDialog(slotProps.data)">
                  <i class="ga-icon ga-external text-2xl text-primary" />
                </span>
                <span class="cursor-pointer" v-if="isFuture(slotProps.data)" @click="openUpdateDialog(slotProps.data)">
                  <i class="ga-icon ga-edit text-2xl text-primary" />
                </span>
                <span class="cursor-pointer" v-if="isFuture(slotProps.data)" @click="openDeleteDialog(slotProps.data)">
                  <i class="ga-icon ga-trash text-2xl text-red" />
                </span>
              </div>
            </template>
          </Column>
        </AkDatatablePagination>
      </div>
    </template>

    <template #extra>
      <AkDialog
        ref="dialogCreate"
        :title="$t('recapitulatif_facturation.add')"
        :validate-label="$t('add')"
        width="1200px"
        @validate="this.create()">
        <ParametrageCommissionForm ref="formCreate" v-model="item" :inputDisabled="false" :dateVisible="true" />
      </AkDialog>

      <AkDialog
        ref="dialogUpdate"
        :title="$t('recapitulatif_facturation.update')"
        :validate-label="$t('update')"
        width="1200px"
        @validate="this.update()">
        <ParametrageCommissionForm ref="formUpdate" v-model="item" :inputDisabled="false" :dateVisible="false" />
      </AkDialog>

      <AkDialog
        ref="dialogDetails"
        :title="this.findDisplay(this.item)"
        width="1200px"
        :show-validate-button="false"
        :show-cancel-button="false">
        <ParametrageCommissionForm
          ref="formDetails"
          v-if="item"
          v-model="item"
          :inputDisabled="true"
          :dateVisible="true" />
      </AkDialog>

      <AkDialog
        ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span>{{ $t('recapitulatif_facturation.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </GaListTabView>
</template>
