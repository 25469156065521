import commercialService from '@services/commercialService';

export default {
  methods: {
    autocompleteCommercial(e) {
      return commercialService.autocomplete(e);
    },
    autocompleteCommercialById(e) {
      return commercialService.commercialDisplay(e);
    },
  },
};
