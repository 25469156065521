import {createStore} from 'vuex';
import TokenService from '@services/tokenService';
import route from '@router';
import jwt_decode from 'jwt-decode';
import roleService from '@services/roleService';
import {useCompanyStore} from '@store/companyStore';

export default createStore({
  state: {
    currentUser: getSavedState('auth.currentUser'),
    userInformation: getSavedState('userInformation'),
    nbActivityAdded: 0,
    nbTicketStatusChanged: 0,
    refreshPromise: null,
    packageVersion: process.env.PACKAGE_VERSION || '0',
    title: undefined,
  },
  mutations: {
    SET_CURRENT_USER(state, newValue) {
      state.currentUser = newValue;
      saveState('auth.currentUser', newValue);
    },
    SET_USER_INFO(state, newValue) {
      if (newValue && newValue.jwt) {
        const decoded = jwt_decode(newValue.jwt);
        const value = {
          userId: decoded.userId,
          firstName: decoded.firstName,
          lastName: decoded.lastName,
          email: decoded.login,
          roleKey: decoded.roleKey,
          roleType: decoded.roleType,
          companyId: decoded.companyId,
          employeeId: decoded.employeeId,
          partenaireId: decoded.partenaireId,
          housekeeperId: decoded.housekeeperId,
          resetPassword: decoded.resetPassword,
          isPoleComptabiliteMandante: decoded.isPoleComptabiliteMandante,
          ownerId: decoded.ownerId,
          commercialId: decoded.commercialId,
        };
        state.userInformation = value;
        window.localStorage.setItem('refreshToken', newValue.refreshToken);
        saveState('userInformation', value);
      } else {
        removeState('userInformation');
      }
    },
    SET_USER_PERMISSIONS(state, newValue) {
      state.userInformation.permissions = newValue;
      saveState('userInformation', state.userInformation);
    },
    SET_TITLE(state, newValue) {
      state.title = newValue;
    },
    ACTIVITY_ADDED(state) {
      state.nbActivityAdded++;
    },
    TICKET_STATUS_CHANGED(state) {
      state.nbTicketStatusChanged++;
    },
  },
  getters: {
    // Whether the user is currently logged in.
    loggedIn(state) {
      return !!state.currentUser;
    },
    username(state) {
      return state.userInformation.firstName;
    },
    userId(state) {
      return state.userInformation.userId;
    },
    refreshPromise(state) {
      return state.refreshPromise;
    },
    appVersion: (state) => {
      return state.packageVersion;
    },
    title: (state) => {
      return state.title;
    },
  },
  actions: {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({dispatch}) {
      dispatch('validate');
    },
    setTitle({commit, dispatch, getters}, {title} = {}) {
      commit('SET_TITLE', title);
    },
    // Logs in the current user.
    logIn({commit, dispatch, getters}, {username, password} = {}) {
      if (getters.loggedIn) return dispatch('validate');
      var user = {login: username, password: password};
      return TokenService.login(user).then((data) => {
        const user = data;
        commit('SET_CURRENT_USER', user);
        commit('SET_USER_INFO', user);
        return roleService.permissions(this.state.userInformation.roleKey).then((res) => {
          commit('SET_USER_PERMISSIONS', res);
          return user;
        });
      });
    },
    refresh({commit, dispatch, getters, state}) {
      if (null != state.refreshPromise) return state.refreshPromise;

      var refreshToken = {
        refreshToken: window.localStorage.getItem('refreshToken'),
        login: state.currentUser.login,
      };
      state.refreshPromise = TokenService.refresh(refreshToken)
        .then((data) => {
          state.refreshPromise = null;
          const user = data;
          commit('SET_CURRENT_USER', user);
          commit('SET_USER_INFO', user);
          return roleService.permissions(this.state.userInformation.roleKey).then((res) => {
            commit('SET_USER_PERMISSIONS', res);
            return user;
          });
        })
        .finally(() => {
          state.refreshPromise = null;
        });

      return state.refreshPromise;
    },

    // Logs out the current user.
    logOut({commit}) {
      window.localStorage.removeItem('filters');
      useCompanyStore().clear();
      commit('SET_CURRENT_USER', null);
      commit('SET_USER_INFO', null);
      route.push({name: 'login'});
    },

    logOutSilent({commit}) {
      useCompanyStore().clear();
      window.localStorage.removeItem('filters');
      commit('SET_CURRENT_USER', null);
      commit('SET_USER_INFO', null);
    },

    logOutExpired({commit}) {
      window.localStorage.removeItem('filters');
      useCompanyStore().clear();
      commit('SET_CURRENT_USER', null);
      commit('SET_USER_INFO', null);
      route.push({name: 'login', query: {redirectFrom: route.currentRoute.value.fullPath}});
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    validate({state}) {
      if (!state.currentUser) return Promise.resolve(null);
      return state.currentUser;
    },
  },
  modules: {},
});

// ===
// Private helpers
// ===

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

function removeState(key) {
  window.localStorage.removeItem(key);
}
