<script>
/* MIXINS */
import countryConst from '@mixins/const/countryConst';

export default {
  components: {},
  mixins: [countryConst],
  data() {
    return {
      geolocation: {
        geocoder: null,
        loc: null,
        position: null,
      },
    };
  },
  props: {
    modelValue: {
      required: true,
    },
    alwaysDisplayMap: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    markerPosition() {
      if (this.currentValue.latitude && this.currentValue.longitude)
        return {lat: parseFloat(this.currentValue.latitude), lng: parseFloat(this.currentValue.longitude)};
      return undefined;
    },
  },
  methods: {},
};
</script>

<template>
  <div class="grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-2">
    <div class="flex flex-col gap-1">
      <span class="font-inter font-semibold text-dark text-base">{{ $t('address.address') }}</span>
      <span class="font-inter font-medium text-gray text-md">{{ currentValue.address ?? '---' }}</span>
    </div>

    <div class="flex flex-col gap-1">
      <span class="font-inter font-semibold text-dark text-base">{{ $t('address.complement') }}</span>
      <span class="font-inter font-medium text-gray text-md">{{ this.currentValue.complement ?? '---' }}</span>
    </div>

    <div class="flex flex-col gap-1">
      <span class="font-inter font-semibold text-dark text-base">{{ $t('address.postalCode') }}</span>
      <span class="font-inter font-medium text-gray text-md">{{ this.currentValue.postalCode ?? '---' }}</span>
    </div>

    <div class="flex flex-col gap-1">
      <span class="font-inter font-semibold text-dark text-base">{{ $t('address.city') }}</span>
      <span class="font-inter font-medium text-gray text-md">{{ this.currentValue.city ?? '---' }}</span>
    </div>

    <div class="flex flex-col gap-1">
      <span class="font-inter font-semibold text-dark text-base">{{ $t('address.state') }}</span>
      <span class="font-inter font-medium text-gray text-md">{{ currentValue.state ?? '---' }}</span>
    </div>

    <div class="flex flex-col gap-1">
      <span class="font-inter font-semibold text-dark text-base">{{ $t('address.country') }}</span>
      <span class="font-inter font-medium text-gray text-md">{{
        this.displayForCountryConst(currentValue.country)
      }}</span>
    </div>

    <div v-if="markerPosition && alwaysDisplayMap" class="md:col-span-2 col-span-1">
      <GMapMap
        :center="markerPosition"
        :options="{gestureHandling: 'cooperative', mapId: 'MAP_ID'}"
        :zoom="15"
        style="width: 100%; height: 300px; border-radius: 8px">
        <GMapMarker :clickable="false" :position="markerPosition" />
      </GMapMap>
    </div>
  </div>
</template>
