<script>
/* COMPOSANTS */
import PrestatairePageMissions from './tabs/PrestatairePageMissions.vue';
import GaTripleView from '@components/v2/layout/GaTripleView.vue';
import AkAvatar from '@components/general/AkAvatar.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import PrestataireUpdateSidebar from '@views/prestataires/PrestataireUpdateSidebar.vue';
import AkButtonActionValidation from '@components/v2/general/AkButtonActionValidation.vue';

/* MIXINS */
import randomRef from '@mixins/randomRef';
import backMixin from '@mixins/backMixin';
import messageMixin from '@mixins/messageMixin';
import companyMixin from '@mixins/companyMixin';
import permissionsMixin from '@mixins/permissionsMixin';
import civilitiesConst from '@mixins/const/civilitiesConst';
import UtilsMixin from '@mixins/utilsMixin';
import LoaderMixin from '@mixins/loaderMixin';

/* SERVICES */
import prestataireService from '@services/prestataireService';
import ficService from '@services/ficService';
import AkFicListColRight from '@components/v2/general/AkFicListColRight.vue';

export default {
  components: {
    AkButtonActionValidation,
    AkFicListColRight,
    PrestataireUpdateSidebar,
    AkButtonAction,
    GaTripleView,
    PrestatairePageMissions,
    AkAvatar,
  },
  mixins: [
    randomRef,
    backMixin,
    messageMixin,
    companyMixin,
    permissionsMixin,
    civilitiesConst,
    UtilsMixin,
    LoaderMixin,
  ],
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      prestataire: {
        id: null,
        companyId: null,
        contact: {
          civility: '',
          firstName: '',
          lastName: '',
          birthDate: new Date(),
          email: '',
          phone: '',
        },
        files: [],
        newFiles: [],
      },
    };
  },
  mounted() {
    this.initGoBack();
    this.refresh();
  },
  computed: {
    title() {
      return this.prestataire.contact.firstName + ' ' + this.prestataire.contact.lastName;
    },
  },
  methods: {
    refresh() {
      this.showTotalLoaderGuestadom();
      prestataireService
        .get(this.$route.params.prestataireId)
        .then((data) => {
          this.prestataire = data;
        })
        .catch((e) => {
          this.addError(e.response.data.error);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    enablePrestataire() {
      prestataireService
        .enable(this.prestataire.id)
        .then(() => {
          this.prestataire.enabled = true;
          this.success(this.$t('prestataire.activated'));
        })
        .catch((e) => {
          this.addError(e.response.data.error);
        })
        .finally(() => {
          if (this.$refs.btnEnable) this.$refs.btnEnable.init();
        });
    },
    disablePrestataire() {
      prestataireService
        .disable(this.prestataire.id)
        .then(() => {
          this.prestataire.enabled = false;
          this.success(this.$t('prestataire.deactivated'));
        })
        .catch((e) => {
          this.addError(e.response.data.error);
        })
        .finally(() => {
          if (this.$refs.btnDisable) this.$refs.btnDisable.init();
        });
    },
    openUpdateSidebar() {
      if (this.$refs.prestataireUpdateSidebar) this.$refs.prestataireUpdateSidebar.show(this.prestataire.id);
    },
    reloadFiles() {
      ficService.fics({tableName: 'prestataire', tableId: this.prestataire.id}).then((data) => {
        this.prestataire.files = data;
      });
    },
  },
};
</script>

<template>
  <GaTripleView>
    <template #action>
      <AkButtonActionValidation
        v-if="!prestataire.enabled"
        ref="btnEnable"
        label="Activer le prestataire"
        :messagePopup="$t('confirm_enable', [prestataire.display])"
        :titlePopup="$t('confirm_enable_title', [prestataire.display])"
        success
        @click="enablePrestataire" />
      <AkButtonActionValidation
        v-else
        ref="btnDisable"
        label="Désactiver le prestataire"
        :messagePopup="$t('confirm_disable', [prestataire.display])"
        :titlePopup="$t('confirm_disable_title', [prestataire.display])"
        danger
        @click="disablePrestataire" />

      <AkButtonAction label="Modifier le prestataire" @click="openUpdateSidebar" />
    </template>
    <template #left>
      <div class="flex flex-col gap-4 md:gap-2">
        <div class="w-[160px] self-center">
          <AkAvatar
            ref="avatar"
            :first-name="prestataire.contact.firstName"
            :last-name="prestataire.contact.lastName"
            :user-id="prestataire.userId" />
        </div>

        <div class="flex items-center gap-2 mt-3">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement(this.$refs, 'data', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">Informations</span>
        </div>
        <div ref="data">
          <div class="grid grid-cols-3 mt-4 gap-4 md:gap-2">
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('prestataire.civility') }}</span>
              <span class="font-inter font-medium text-gray text-md">{{
                displayForCivilityConst(prestataire.contact.civility)
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('prestataire.lastName') }}</span>
              <span class="font-inter font-medium text-gray text-md">{{ prestataire.contact.lastName }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('prestataire.firstName') }}</span>
              <span class="font-inter font-medium text-gray text-md">{{ prestataire.contact.firstName }}</span>
            </div>
          </div>
          <div class="grid grid-cols-2 mt-4 gap-4 md:gap-2">
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('prestataire.birthDate') }}</span>
              <span class="font-inter font-medium text-gray text-md">{{
                formatDateIfNeeded(prestataire.contact.birthDate)
              }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('prestataire.company') }}</span>
              <span class="font-inter font-medium text-gray text-md">{{
                displayForCompany(prestataire.companyId)
              }}</span>
            </div>
          </div>
        </div>

        <div class="flex items-center gap-2 mt-3">
          <i
            class="ga-icon ga-chevron_down text-primary text-2xl hover:cursor-pointer"
            @click="toggleElement(this.$refs, 'coordinates', $event)" />
          <span class="font-inter text-dark font-semibold text-lg">Coordonnées</span>
        </div>
        <div ref="coordinates">
          <div class="grid grid-cols-1 mt-4 gap-4 md:gap-2">
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('prestataire.email') }}</span>
              <span class="font-inter font-medium text-gray text-md">{{ prestataire.contact.email }}</span>
            </div>
          </div>
          <div class="grid grid-cols-2 mt-4 gap-4 md:gap-2">
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('prestataire.phone') }}</span>
              <span class="font-inter font-medium text-gray text-md">{{ prestataire.contact.phone }}</span>
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-inter font-semibold text-dark text-base">{{ $t('prestataire.homePhone') }}</span>
              <span class="font-inter font-medium text-gray text-md">{{ prestataire.contact.homePhone }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <PrestatairePageMissions :prestataire-id="this.$route.params.prestataireId" />
    </template>
    <template #right>
      <AkFicListColRight
        v-if="prestataire"
        v-model="prestataire.files"
        :table-id="prestataire.id"
        can-delete
        can-upload
        fic-type="PRESTATAIRE_REGISTRATION"
        table-name="prestataire"
        title="Pièces jointes" />
    </template>

    <template #extra>
      <PrestataireUpdateSidebar ref="prestataireUpdateSidebar" @update="refresh" @updateFile="reloadFiles" />
    </template>
  </GaTripleView>
</template>
