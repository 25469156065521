<script>
/* COMPOSANTS */
import GaView from '@components/v2/layout/GaView.vue';
import AkDatatablePagination from '@components/v2/general/AkDatatablePagination.vue';
// import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import GaListView from '@components/v2/layout/GaListView.vue';
import AkAutocompleteFilter from '@components/v2/filter/AkAutocompleteFilter.vue';
import AkAllFilter from '@components/v2/filter/AkAllFilter.vue';
import AkInputFilter from '@components/v2/filter/AkInputFilter.vue';
import AkInputSwitch from '@components/v2/input/AkInputSwitch.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';
import messageMixin from '@mixins/messageMixin';
import commercialAutocompleteMixin from '@mixins/autocomplete/commercialAutocompleteMixin';

/* SERVICES */
import commercialService from '@services/commercialService';

export default {
  components: {
    AkInputSwitch,
    AkInputFilter,
    AkAllFilter,
    AkAutocompleteFilter,
    GaListView,
    // AkButtonAction,
    AkDatatablePagination,
    GaView,
  },
  mixins: [permissionsMixin, utilsMixin, sidebarMixin, loaderMixin, messageMixin, commercialAutocompleteMixin],
  metaInfo() {
    return {
      title: 'commercial.list',
    };
  },
  data() {
    return {
      list: [],
      loading: true,
      query: '',
      filter: {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      },
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      this.loading = true;
      commercialService.commercials(this.filter).then((data) => {
        this.list = data;
        this.loading = false;
      });
    },
    hasUserId(data) {
      return !!data.userId;
    },
    changeEnabled(data) {
      if (!this.hasUserId(data)) {
        this.addError(this.$t('commercial.errorNoId'));
        data.userEnabled = false;
        return;
      }
      if (data.userEnabled) this.enable(data);
      else this.disable(data);
    },
    enable(commercial) {
      commercialService
        .enable(commercial.id)
        .then((data) => {
          this.success(this.$t('commercial.enabled'));
          commercial.userEnabled = data.userEnabled;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        });
    },
    disable(commercial) {
      commercialService
        .disable(commercial.id)
        .then((data) => {
          this.success(this.$t('commercial.disabled'));
          commercial.userEnabled = data.userEnabled;
        })
        .catch((e) => {
          let message = this.msgForErr(this.$t, e);
          this.addError(message);
        });
    },
    resetFilter() {
      this.filter = {
        page: 1,
        limit: 50,
        sort: ['id:DESC'],
      };
      this.toggleFilterTitle(
        'GaCommercialFilterList',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },
    openFilterPanel() {
      this.toggleFilterTitle(
        'GaCommercialFilterList',
        this.$t('filter_all'),
        this.filter,
        this.search,
        this.resetFilter,
      );
    },
    rowClick(event) {
      let data = event.data;
      this.$router.push({
        name: 'commercialPage',
        params: {commercialId: data.id},
      });
    },
    // exportList() {
    //   this.showTotalLoaderWithAfter(this.$t('export_in_progress'));
    //   commercialService
    //     .exportList(this.filter)
    //     .catch((e) => {
    //       let message = this.msgForErr(this.$t, e);
    //       this.addError(message);
    //     })
    //     .finally(() => {
    //       this.hideLoader();
    //     });
    // },
  },
};
</script>

<template>
  <GaView>
    <template #action>
      <!-- <AkButtonAction secondary :label="$t('download')" little @click="exportList" /> -->
      <router-link :to="{name: 'commercialCreate'}" class="xs btn-primary-gradient">
        {{ $t('add') }}
      </router-link>
    </template>
    <template #content>
      <GaListView>
        <template #filter>
          <AkInputFilter v-model="filter.contactCompanyName" :label="$t('contact.companyName')" @search="search" />
          <AkAutocompleteFilter
            v-model="filter.id"
            :init-method="autocompleteCommercialById"
            :label="$t('commercial.representant')"
            :search-method="autocompleteCommercial"
            direction="right"
            item-display="display"
            item-value="id"
            @search="search" />
          <AkAllFilter @open="openFilterPanel" />
        </template>
        <template #content>
          <AkDatatablePagination :lazy="false" :loading="loading" :value="list">
            <template #empty>
              {{ $t('commercial.empty') }}
            </template>
            <Column
              :header="$t('interlocutor.commercial')"
              :sortable="true"
              header-style="width: 200px;"
              sort-field="contactLastName">
              <template #body="slotProps">
                <router-link :to="{name: 'commercialPage', params: {commercialId: slotProps.data.id}}">
                  {{ slotProps.data.display }}
                </router-link>
              </template>
            </Column>
            <Column :header="$t('contact.companyName')" :sortable="true" field="companyName" sort-field="companyName" />
            <Column
              :header="$t('contact.email')"
              :sortable="true"
              field="email"
              header-style="width: 200px;"
              sort-field="email" />
            <Column
              :header="$t('contact.phone')"
              :sortable="true"
              field="phone"
              header-style="width: 150px;"
              sort-field="phone" />
            <Column
              :header="$t('user.enabled')"
              :sortable="true"
              body-style="text-align:center"
              header-style="width: 120px;"
              sort-field="userEnabled">
              <template #body="slotProps">
                <AkInputSwitch
                  validationPopup
                  :nameOnPopup="slotProps.data.display"
                  v-model="slotProps.data.userEnabled"
                  :disabled="!hasUserId(slotProps.data)"
                  @change="changeEnabled(slotProps.data)" />
              </template>
            </Column>
            <Column header-style="width: 50px;">
              <template #body="slotProps">
                <div class="flex items-center gap-2">
                  <router-link :to="{name: 'commercialPage', params: {commercialId: slotProps.data.id}}">
                    <i class="ga-icon ga-external text-2xl text-primary" />
                  </router-link>
                  <router-link :to="{name: 'commercialUpdate', params: {commercialId: slotProps.data.id}}">
                    <i class="ga-icon ga-edit text-2xl text-primary" />
                  </router-link>
                </div>
              </template>
            </Column>
          </AkDatatablePagination>
        </template>
      </GaListView>
    </template>
  </GaView>
</template>
