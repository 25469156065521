import Api from '@/services/api';
import {removeEmptyParams} from '@utils';

export default {
  commercials(filter) {
    return Api()
      .get('/commercials', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  autocomplete(filter) {
    return Api()
      .get('/commercials/autocomplete', {params: removeEmptyParams(filter)})
      .then((res) => {
        res.data.forEach((e) => {
          this.formatRes(e);
        });
        return res.data;
      });
  },
  commercial(id) {
    return Api()
      .get('/commercial/' + id)
      .then((res) => this.formatRes(res.data));
  },
  commercialDisplay(id) {
    return Api()
      .get('/commercial/' + id + '/display')
      .then((res) => this.formatRes(res.data));
  },
  create(commercial) {
    return Api()
      .post('/commercial', commercial)
      .then((res) => this.formatRes(res.data));
  },
  access(id) {
    return Api()
      .post('/commercial/' + id + '/access')
      .then((res) => this.formatRes(res.data));
  },
  update(commercial) {
    return Api()
      .put('/commercial/' + commercial.id, commercial)
      .then((res) => this.formatRes(res.data));
  },
  enable(id) {
    return Api()
      .put('/commercial/enable/' + id)
      .then((res) => this.formatRes(res.data));
  },
  disable(id) {
    return Api()
      .put('/commercial/disable/' + id)
      .then((res) => this.formatRes(res.data));
  },
  delete(commercial) {
    return Api()
      .delete('/commercial/' + commercial.id)
      .then(() => commercial);
  },
  formatRes(e) {
    return e;
  },
};
