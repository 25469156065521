import store from '@store';

export default {
  isAdmin() {
    let roleType = store.state.userInformation.roleType;
    return roleType === 'ADMIN';
  },
  isGav() {
    let roleType = store.state.userInformation.roleType;
    return roleType === 'GAV';
  },
  isGas() {
    let roleType = store.state.userInformation.roleType;
    return roleType === 'GAS';
  },
  isGasGav() {
    let roleType = store.state.userInformation.roleType;
    return roleType === 'GAS' || roleType === 'GAV';
  },
  isOwner() {
    let roleType = store.state.userInformation.roleType;
    return roleType === 'OWNER';
  },
  isDeveloper() {
    let roleType = store.state.userInformation.roleType;
    return roleType === 'DEVELOPER';
  },
  isPrestataire() {
    let roleType = store.state.userInformation.roleType;
    return roleType === 'PRESTATAIRE';
  },
  isPartenaire() {
    let roleType = store.state.userInformation.roleType;
    return roleType === 'PARTENAIRE';
  },
  isHousekeeper() {
    let roleType = store.state.userInformation.roleType;
    return roleType === 'HOUSEKEEPER';
  },
  isCommercial() {
    let roleType = store.state.userInformation.roleType;
    return roleType === 'COMMERCIAL';
  },
  isMe(userId) {
    let tmp = store.state.userInformation.userId;
    return tmp === userId;
  },
  hasPermission(permission) {
    let permissions = store.state.userInformation.permissions;
    if (!permissions) return false;
    return permissions.includes(permission);
  },
  hasOneOfThem(permissions) {
    if (!permissions) return false;
    return permissions.some(this.hasPermission);
  },
  myUserId() {
    return store.state.userInformation.userId;
  },
  myEmployeeId() {
    return store.state.userInformation.employeeId;
  },
  myCompanyId() {
    return store.state.userInformation.companyId;
  },
  myOwnerId() {
    return store.state.userInformation.ownerId;
  },
  myPartenaireId() {
    return store.state.userInformation.partenaireId;
  },
  myHousekeeperId() {
    return store.state.userInformation.housekeeperId;
  },
  myCommercialId() {
    return store.state.userInformation.commercialId;
  },
  isOneOfThem(roles) {
    let roleType = store.state.userInformation.roleType;
    return roles.includes(roleType);
  },
  isPoleComptabiliteMandante() {
    return store.state.userInformation.isPoleComptabiliteMandante;
  },
};
