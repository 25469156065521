<script>
import useVuelidate from '@vuelidate/core';
import {required, requiredIf} from '@vuelidate/validators';

/* COMPOSANTS */
import ParametrageCommissionRow from './ParametrageCommissionRow';
import AkInputMoney from '@components/v2/input/AkInputMoney';
import AkInputPercentage from '@components/v2/input/AkInputPercentage';
import AkDropdown from '@components/v2/input/AkDropdown';
import AkCalendar from '@components/v2/input/AkCalendar';

/* MIXINS */
import comptaCommissionTypeConst from '@mixins/const/comptaCommissionTypeConst';

export default {
  components: {
    ParametrageCommissionRow,
    AkCalendar,
    AkInputMoney,
  },
  mixins: [comptaCommissionTypeConst],
  props: {
    modelValue: {
      required: true,
    },
    inputDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dateVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  emits: ['update:modelValue'],
  data() {
    return {};
  },
  validations() {
    return {
      currentValue: {
        date: {required},

        // FRAIS DE MENAGE INTERLOCATIFS
        fraisDeMenageVoyageurType: {required: requiredIf(this.allInclusive)},
        fraisDeMenageVoyageurGav: {required: requiredIf(this.allInclusive)},
        fraisDeMenageVoyageurGas: {required: requiredIf(this.allInclusive)},

        // FRAIS D'ACCUEIL
        fraisAccueilType: {required: requiredIf(this.allInclusive)},
        fraisAccueilGav: {required: requiredIf(this.allInclusive)},
        fraisAccueilGas: {required: requiredIf(this.allInclusive)},

        // FRAIS DE BLANCHISSERIE
        fraisDeBlanchisserieType: {required: requiredIf(this.allInclusive)},
        fraisDeBlanchisserieGav: {required: requiredIf(this.allInclusive)},
        fraisDeBlanchisserieGas: {required: requiredIf(this.allInclusive)},

        // FRAIS D'ASSURANCE
        fraisAssuranceType: {required: requiredIf(this.allInclusive)},
        fraisAssuranceGav: {required: requiredIf(this.allInclusive)},
        fraisAssuranceGas: {required: requiredIf(this.allInclusive)},

        // FRAIS DE SERVICE
        fraisDeServiceGaType: {required},
        fraisDeServiceGaGav: {required},
        fraisDeServiceGaGas: {required},

        // ACHAT HOTE
        achatHoteType: {required: requiredIf(this.allInclusive)},
        achatHoteGav: {required: requiredIf(this.allInclusive)},
        achatHoteGas: {required: requiredIf(this.allInclusive)},

        // DEEP CLEANING
        deepCleaningType: {required: requiredIf(this.allInclusive)},
        deepCleaningGav: {required: requiredIf(this.allInclusive)},
        deepCleaningGas: {required: requiredIf(this.allInclusive)},

        // FRAIS D'OUVERTURE HIVERNAGE
        ouvertureHivernageType: {required: requiredIf(this.allInclusive)},
        ouvertureHivernageGav: {required: requiredIf(this.allInclusive)},
        ouvertureHivernageGas: {required: requiredIf(this.allInclusive)},

        // FRAIS DE FERMETURE HIVERNAGE
        fermetureHivernageType: {required: requiredIf(this.allInclusive)},
        fermetureHivernageGav: {required: requiredIf(this.allInclusive)},
        fermetureHivernageGas: {required: requiredIf(this.allInclusive)},

        // FORFAIT ANNULATION
        forfaitFraisAnnulationHoteType: {required},
        forfaitFraisAnnulationHoteGav: {required},
        forfaitFraisAnnulationHoteGas: {required},

        // FRAIS DE MAINTENANCE
        fraisDeMaintenanceType: {required},
        fraisDeMaintenanceGav: {required},
        fraisDeMaintenanceGas: {required},

        // TAUX DE FRAIS DE MAINTENANCE
        fraisDeMaintenanceTaux: {required},

        // FRAIS DE MENAGE DEPART HOTE
        fraisDeMenageHoteType: {required: requiredIf(this.allInclusive)},
        fraisDeMenageHoteGav: {required: requiredIf(this.allInclusive)},
        fraisDeMenageHoteGas: {required: requiredIf(this.allInclusive)},

        // NUISANCE SONORE
        nuisanceSonoreType: {required},
        nuisanceSonoreGav: {required},
        nuisanceSonoreGas: {required},

        // FRAIS DE LANCEMENT
        fraisDeLancementType: {required},
        fraisDeLancementGav: {required},
        fraisDeLancementGas: {required},

        // FRAIS DE MAINTENANCE (ANCIEN) (historique : utilisé seulement à l'affichage)
        fraisDeMaintenanceOldType: {},
        fraisDeMaintenanceOldGav: {},
        fraisDeMaintenanceOldGas: {},

        // FRAIS DE VERIFICATION MENAGE HOTE (historique : utilisé seulement à l'affichage)
        forfaitVerificationMenageHoteType: {},
        forfaitVerificationMenageHoteGav: {},
        forfaitVerificationMenageHoteGas: {},
      },
    };
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    allInclusive() {
      return this.currentValue.offer == 'ALL_INCLUSIVE';
    },
  },
  methods: {
    validateForm() {
      this.v$.$touch();
      return !this.v$.currentValue.$error;
    },
  },
};
</script>

<template>
  <div v-if="this.currentValue" class="flex flex-col gap-2">
    <AkCalendar
      v-if="dateVisible"
      v-model="currentValue.date"
      class-name="col-md-6"
      :label="$t('operation.date')"
      date-format="dd/mm/yy"
      selection-mode="single"
      :validator="v$.currentValue.date"
      :disabled="inputDisabled" />

    <!--FRAIS DE MENAGE INTERLOCATIFS-->

    <ParametrageCommissionRow
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="fraisDeMenageVoyageur"
      :label="this.allInclusive ? 'Frais de ménage interlocatifs' : 'Frais de ménage voyageur'" />

    <!--FRAIS D'ACCUEIL-->

    <ParametrageCommissionRow
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="fraisAccueil"
      label="Frais d'accueil" />

    <!--FRAIS DE BLANCHISSERIE-->

    <ParametrageCommissionRow
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="fraisDeBlanchisserie"
      label="Frais de blanchisserie" />

    <!--FRAIS D'ASSRANCE-->

    <ParametrageCommissionRow
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="fraisAssurance"
      label="Frais d'assurance" />

    <!--FRAIS DE SERVICE-->

    <ParametrageCommissionRow
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="fraisDeServiceGa"
      label="Frais de service" />

    <!--ACHAT HOTE-->

    <ParametrageCommissionRow
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="achatHote"
      label="Achat Hote" />

    <!--DEEP CLEANING-->

    <ParametrageCommissionRow
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="deepCleaning"
      label="Deep cleaning" />

    <!--FRAIS D'OUVERTURE HIVERNAGE-->

    <ParametrageCommissionRow
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="ouvertureHivernage"
      label="Ouverture hivernage" />

    <!--FRAIS DE FERMETURE HIVERNAGE-->

    <ParametrageCommissionRow
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="fermetureHivernage"
      label="Fermeture hivernage" />

    <!--FORFAIT ANNULATION-->

    <ParametrageCommissionRow
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="forfaitFraisAnnulationHote"
      label="Forfait annulation" />

    <!--FRAIS DE MAINTENANCE-->

    <ParametrageCommissionRow
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="fraisDeMaintenance"
      label="Frais de maintenance" />

    <!--TAUX DE FRAIS DE MAINTENANCE-->

    <div class="flex" v-if="this.allInclusive">
      <AkInputMoney
        v-model="currentValue.fraisDeMaintenanceTaux"
        :validator="v$.currentValue.fraisDeMaintenanceTaux"
        :label="this.$t('recapitulatif_facturation.frais_maintenance_taux')"
        :disabled="inputDisabled"
        class-name="col-md-4" />
    </div>

    <!--FRAIS DE MENAGE DEPART HOTE-->

    <ParametrageCommissionRow
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="fraisDeMenageHote"
      label="Frais de ménage départ hôte" />

    <!--NUISANCE SONORE-->

    <ParametrageCommissionRow
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="nuisanceSonore"
      label="Nuisances sonores" />

    <!--FRAIS DE LANCEMENT-->

    <ParametrageCommissionRow
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="fraisDeLancement"
      label="Frais de lancement" />

    <!--FRAIS DE MAINTENANCE (ANCIEN) (READ ONLY)-->

    <ParametrageCommissionRow
      v-if="this.inputDisabled && this.allInclusive"
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="fraisDeMaintenanceOld"
      label="Frais de maintenance (ancien)" />

    <!--FRAIS DE VERIFICATION MENAGE HOTE (READ ONLY)-->

    <ParametrageCommissionRow
      v-if="this.inputDisabled && this.allInclusive"
      v-model="currentValue"
      :inputDisabled="inputDisabled"
      :validator="v$"
      name="forfaitVerificationMenageHote"
      label="Forfait vérification ménage hôte" />
  </div>
</template>
