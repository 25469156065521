<script>
/* COMPOSANTS */
import AkDropdown from '@components/v2/input/AkDropdown';
import AkInputMoney from '@components/v2/input/AkInputMoney';
import AkInputPercentage from '@components/v2/input/AkInputPercentage';
import AkInputText from '@components/v2/input/AkInputText';

/* MIXINS */
import comptaCommissionTypeConst from '@mixins/const/comptaCommissionTypeConst';

export default {
  components: {
    AkDropdown,
    AkInputMoney,
    AkInputPercentage,
    AkInputText,
  },
  mixins: [comptaCommissionTypeConst],

  props: {
    modelValue: {
      required: true,
    },
    validator: {
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    inputDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {nothing: ''};
  },

  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },

    isRequired() {
      let r = this.validatorType.required;
      return (
        r !== undefined &&
        (r.$params.type === 'required' || (r.$params.type === 'requiredIf' && r.$params.prop === true))
      );
    },

    offer() {
      return this.currentValue.offer;
    },
    allInclusive() {
      return this.offer == 'ALL_INCLUSIVE';
    },

    fieldType() {
      return this.name + 'Type';
    },
    fieldGav() {
      return this.name + 'Gav';
    },
    fieldGas() {
      return this.name + 'Gas';
    },

    valueType() {
      return this.currentValue[this.fieldType];
    },
    valueGav() {
      return this.currentValue[this.fieldGav];
    },
    valueGas() {
      return this.currentValue[this.fieldGas];
    },

    validatorType() {
      return this.validator.currentValue[this.fieldType];
    },
    validatorGav() {
      return this.validator.currentValue[this.fieldGav];
    },
    validatorGas() {
      return this.validator.currentValue[this.fieldGas];
    },

    labelType() {
      return this.label + ' (Type)';
    },
    labelGav() {
      return this.label + ' (Partenaire)';
    },
    labelGas() {
      return this.label + ' (Guestadom)';
    },

    isTypePCT() {
      return this.valueType === 'PCT';
    },
    isTypeFixe() {
      return this.valueType === 'FIXE';
    },
  },

  methods: {
    typeChanged() {
      if (this.isTypeFixe) {
        this.currentValue[this.fieldGav] = null;
        this.currentValue[this.fieldGas] = null;
      } else if (this.isTypePCT) {
        this.currentValue[this.fieldGav] = 0;
        this.currentValue[this.fieldGas] = 100;
      }
    },

    gavChanged() {
      let v = this.valueGav;
      if (this.isTypeFixe) this.currentValue[this.fieldGas] = v === null ? 0 : -1;
      else if (this.isTypePCT) this.currentValue[this.fieldGas] = v === null ? null : 100 - v;
    },

    gasChanged() {
      let v = this.valueGas;
      if (this.isTypeFixe) {
        this.currentValue[this.fieldGav] = v === null ? 0 : -1;
      }
    },
  },
};
</script>

<template>
  <div class="flex flex-row gap-4" v-if="isRequired">
    <!--FIELD FOR TYPE-->

    <AkDropdown
      v-model="currentValue[fieldType]"
      :label="labelType"
      :options="comptaCommissionTypeConst"
      option-label="label"
      option-value="value"
      class-name="col-md-4"
      :validator="validatorType"
      :disabled="inputDisabled"
      @update:modelValue="typeChanged" />

    <!--FIELD FOR GAV-->

    <AkInputPercentage
      v-if="isTypePCT"
      v-model="currentValue[fieldGav]"
      :validator="validator.currentValue[fieldGav]"
      :disabled="inputDisabled"
      :label="labelGav"
      class-name="col-md-4"
      @update:modelValue="gavChanged" />
    <AkInputMoney
      v-else-if="this.isTypeFixe && this.valueGav !== -1"
      v-model="currentValue[fieldGav]"
      :validator="validatorGav"
      :disabled="inputDisabled"
      :label="labelGav"
      class-name="col-md-4"
      @update:modelValue="gavChanged" />
    <AkInputText v-else v-model="nothing" :label="labelGav" class-name="col-md-4" :disabled="true" />

    <!--FIELD FOR GAS-->

    <AkInputPercentage
      v-if="isTypePCT"
      v-model="currentValue[fieldGas]"
      :label="labelGas"
      class-name="col-md-4"
      :disabled="true" />
    <AkInputMoney
      v-else-if="this.isTypeFixe && this.valueGas !== -1"
      v-model="currentValue[fieldGas]"
      :validator="validatorGas"
      :disabled="inputDisabled"
      :label="labelGas"
      class-name="col-md-4"
      @update:modelValue="gasChanged" />
    <AkInputText v-else v-model="nothing" :label="labelGas" class-name="col-md-4" :disabled="true" />
  </div>
</template>
