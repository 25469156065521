/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';

export default {
  mixins: [permissionsMixin],
  data() {
    return {
      tabs: [
        {
          name: this.$t('recapitulatif_facturation.offer_all_inclusive'),
          id: 'offerAllInclusive',
          icon: 'list',
          hidden: !this.isAdmin(),
        },
        {
          name: this.$t('recapitulatif_facturation.offer_online'),
          id: 'offerOnline',
          icon: 'list',
          hidden: !this.isAdmin(),
        },
      ],
    };
  },
  methods: {},
  computed: {
    isAllIncluse() {
      return this.activeTab === 'offerAllInclusive';
    },
    isOnline() {
      return this.activeTab === 'offerOnline';
    },
  },
};
