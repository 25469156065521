<script>
/* COMPOSANTS */
import AkInputText from '@components/v2/input/AkInputText';
import AkDropdown from '@components/v2/input/AkDropdown';
import MultiSelect from 'primevue/multiselect';
import AkAutocomplete from '@components/v2/input/AkAutocomplete.vue';
import AkButtonFooterSidebarFilter from '@components/v2/general/AkButtonFooterSidebarFilter.vue';

/* MIXINS */
import permissionsMixin from '@mixins/permissionsMixin';
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@/mixins/sidebarMixin';
import accommodationTypeConst from '@mixins/const/accommodationTypeConst';
import residenceTypeConst from '@mixins/const/residenceTypeConst';
import ownerAutocompleteMixin from '@mixins/autocomplete/ownerAutocompleteMixin';
import mandatAutocompleteMixin from '@mixins/autocomplete/mandatAutocompleteMixin';

/* SERVICES */
import companyService from '@services/companyService';
import itemLabelService from '@services/itemLabelService';
import companyMixin from '@mixins/companyMixin';

export default {
  components: {
    AkAutocomplete,
    MultiSelect,
    AkDropdown,
    AkInputText,
    AkButtonFooterSidebarFilter,
  },
  mixins: [
    permissionsMixin,
    ownerAutocompleteMixin,
    mandatAutocompleteMixin,
    utilsMixin,
    sidebarMixin,
    companyMixin,
    accommodationTypeConst,
    residenceTypeConst,
  ],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      mandatMarcheList: undefined,
      enabledStateConst: [
        {label: this.$t('accommodation.all'), value: null},
        {label: this.$t('accommodation.enabled_only'), value: true},
        {label: this.$t('accommodation.disabled_only'), value: false},
      ],
    };
  },
  mounted() {
    let p5 = itemLabelService.itemLabels({type: 'MARCHE'});
    p5.then((data) => {
      this.mandatMarcheList = data;
    });
  },
  methods: {
    search() {
      this.callback[0]();
      this.hideSidebar();
    },
    reset() {
      if (this.$refs.ownerAutocomplete) this.$refs.ownerAutocomplete.reset();
      if (this.$refs.mandatAutocomplete) this.$refs.mandatAutocomplete.reset();
      this.callback[1]();
    },
    itemSelect(item) {
      this.$nextTick(() => {
        this.callback[0]();
      });
    },
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent>
    <MultiSelect
      v-if="isGas()"
      @change="itemSelect"
      v-model="currentValue.companyIds"
      :placeholder="$t('employee.company')"
      :options="this.companies"
      class="form-control"
      option-label="name"
      option-value="id" />

    <AkAutocomplete
      ref="ownerAutocomplete"
      v-model="currentValue.ownerId"
      :inline="true"
      :label="$t('role.owner')"
      option-value="id"
      option-label="display"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteOwnerById"
      :search-method="this.autocompleteOwner"
      :search-object="this.autocompleteOwnerCustomer()"
      :search-label="this.autocompleteOwnerQuery" />

    <AkInputText
      v-model="currentValue.name"
      inline
      @valueChange="itemSelect"
      :display-label="false"
      :placeholder="$t('accommodation_title')"
      class-name="" />

    <AkAutocomplete
      ref="mandatAutocomplete"
      v-model="currentValue.mandatId"
      :inline="true"
      :label="$t('mandat.details')"
      option-value="id"
      option-label="num"
      @item-select="itemSelect"
      :force-selection="false"
      :init-method="this.autocompleteMandatById"
      :search-method="this.autocompleteMandat" />

    <MultiSelect
      v-model="currentValue.accommodationTypes"
      class="form-control"
      @change="itemSelect"
      :options="accommodationTypeConst"
      option-label="label"
      option-value="value"
      :placeholder="$t('accommodation.accommodationType')"
      :label="$t('accommodation.accommodationType')" />

    <MultiSelect
      v-model="currentValue.accommodationTypologies"
      @change="itemSelect"
      :options="getResidenceTypeConstForFilter()"
      class="form-control"
      option-label="label"
      option-value="value"
      :placeholder="$t('accommodation.residenceType')"
      label="$t('accommodation.residenceType')" />

    <!-- Marche -->
    <MultiSelect
      v-model="currentValue.marchesId"
      :options="mandatMarcheList"
      :loading="mandatMarcheList === undefined"
      :placeholder="$t('item_label.marche')"
      class="form-control"
      filter
      filter-placeholder="Filtrer"
      option-label="label"
      option-value="id"
      @change="itemSelect" />

    <AkDropdown
      v-model="currentValue.enabled"
      inline
      @value-change="itemSelect"
      label="Statut"
      :options="enabledStateConst"
      option-label="label"
      option-value="value" />

    <AkButtonFooterSidebarFilter :validateLabel="$t('search.search')" @action="search" @reset="reset" />
  </form>
</template>
