import {createApp} from 'vue';
import {createMetaManager, plugin as vueMetaPlugin} from 'vue-meta';

import App from './App.vue';
import router from './router';
import store from './store';
import 'jquery';
import 'popper.js';
import 'bootstrap';
import 'metismenu';
import {createI18n} from 'vue-i18n';
import {fr} from '@lang/fr.js';
import {en} from '@lang/en.js';
import moment from 'moment/moment';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import VueSmoothScroll from 'vue3-smooth-scroll';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import VueGoogleMaps from '@fawmi/vue-google-maps';

/**PrimeVue**/
import PrimeVue from 'primevue/config';
import VSwatches from 'vue3-swatches';
import VueLoaders from 'vue-loaders';
import GaContactSidebar from '@views/accommodation/details/owner/items/GaContactSidebar';
import Tooltip from 'primevue/tooltip';
import BadgeDirective from 'primevue/badgedirective';

/**LIB**/
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import VuelidatePlugin from '@vuelidate/core';
import {createPinia} from 'pinia/dist/pinia';
import GaReservationSidebar from '@views/reservation/GaReservationSidebar';
import GaCommentSidebar from '@views/accounting/GaCommentSidebar.vue';
import GaView from '@components/layout/GaView';
import GaContentView from '@components/layout/GaContentView';
import GaTabView from '@components/layout/GaTabView';
import Dialog from 'primevue/dialog';
import Sidebar from 'primevue/sidebar';
import Message from 'primevue/message';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import Column from 'primevue/column';
import GaTitleMenu from '@components/ga/general/GaTitleMenu';
import GaItemTitle from '@components/ga/general/GaItemTitle';
import ConfirmationService from 'primevue/confirmationservice';
import GaReservationFilterPlanning from './views/reservation/GaReservationFilterPlanning';
import GaReservationFilterList from './views/reservation/GaReservationFilterList';
import GaAccommodationFilterList from '@views/accommodation/GaAccommodationFilterList.vue';
import GaOperationsConsultationFilterList from '@views/accounting/operations/tabs/GaOperationsConsultationFilterList.vue';
import GaPaymentFilterList from '@views/reservation/GaPaymentFilterList.vue';
import GaThirdPartyAccountFilterList from '@views/accounting/thirdpartyaccount/filter/GaThirdPartyAccountFilterList.vue';
import GaRecapitulatifFacturationFilterList from '@views/accounting/recap/filter/GaRecapitulatifFacturationFilterList.vue';
import GaAccountFilterList from '@views/accounting/filter/GaAccountFilterList.vue';
import GaTransactionsFilterList from '@views/accounting/transactions/filter/GaTransactionsFilterList.vue';
import GaProspectFilterList from '@views/prospect/GaProspectFilterList.vue';
import GaCrgConsultationFilter from './views/accounting/crg/filter/GaCrgConsultationFilter';
import GaCrgListFilter from './views/accounting/crg/filter/GaCrgListFilter';
import GaCrgPaymentFilter from './views/accounting/crg/filter/GaCrgPaymentFilter';
import GaCrgHistoriqueFilter from './views/accounting/crg/filter/GaCrgHistoriqueFilter';
import GaOwnerListFilter from '@views/owner/filter/GaOwnerListFilter.vue';
import GaClientListFilter from '@views/client/filter/GaClientListFilter.vue';
import GaTicketListFilter from '@views/ticket/filter/GaTicketListFilter.vue';
import GaBalanceFilter from '@views/accounting/filter/GaBalanceFilter.vue';
import GaStayRateConsultationFilter from '@views/accounting/stayrates/filter/GaStayRateConsultationFilter.vue';
import GaStayRateTransfertFilter from '@views/accounting/stayrates/filter/GaStayRateTransfertFilter.vue';
import GaCancelFilter from '@views/reservation/GaCancelFilter.vue';
import GaEmployeeListFilter from '@views/employee/filter/GaEmployeeListFilter.vue';
import GaOperationsOwnerChargeFilter from '@views/accounting/operations/filter/GaOperationsOwnerChargeFilter.vue';
import GaParametrageCommissionFilter from '@views/settings/ParametrageCommission/filter/GaParametrageCommissionFilter.vue';
import GaLitigeListFilter from '@views/litige/filter/GaLitigeListFilter.vue';
import GaPartenaireFilterList from '@views/partenaires/GaPartenaireFilterList.vue';
import GaHousekeeperFilterList from '@views/housekeepers/GaHousekeeperFilterList.vue';
import GaPrestataireFilterList from '@views/prestataires/GaPrestataireFilterList.vue';
import GaOwnerCancelListFilter from '@views/accounting/ownercancel/filter/GaOwnerCancelListFilter.vue';
import GaMandatListFilter from '@views/mandat/filter/GaMandatListFilter.vue';
import GaAvenantListFilter from '@views/mandat/filter/GaAvenantListFilter.vue';
import GaMaintenanceListFilter from '@views/maintenance/filter/GaMaintenanceListFilter.vue';
import GaUpsellListFilter from '@views/upsell/filter/GaUpsellListFilter.vue';
import GaMissionPlanningParticipantFilter from '@views/operationnel/mission/filter/GaMissionPlanningParticipantFilter.vue';
import GaMissionSuiviFilter from '@views/operationnel/missionsuivi/filter/GaMissionSuiviFilter.vue';
import GaWaitingOrdersFilter from '@views/operationnel/waitingorders/filter/GaWaitingOrdersFilter.vue';
import GaAccommodationExtrasFilter from '@views/upsell/filter/GaAccommodationExtrasFilter.vue';
import GaMissionDashboardFilter from '@views/operationnel/missiondashboard/filter/GaMissionDashboardFilter';
import GaReviewListFilter from '@views/review/filter/GaReviewListFilter';
import GaReviewSidebar from '@views/review/sidebar/GaReviewSidebar';
import GaAccommodationReviewsFilter from '@views/review/filter/GaAccommodationReviewsFilter';
import VueMobileDetection from 'vue-mobile-detection';
import GaPrestationFilter from '@views/prestation/filter/GaPrestationFilter';
import GaMissionSuiviPartnerFilter from '@views/operationnel/missionsuivi/filter/GaMissionSuiviPartnerFilter';
import GaMissionOrderListFilter from '@views/operationnel/missionorders/filter/GaMissionOrderListFilter';
import GaCrgOwnerFilter from './views/accounting/crg/filter/GaCrgOwnerFilter.vue';
import GaMissionWaitingOrdersSidebar from '@views/operationnel/waitingorders/sidebar/GaMissionWaitingOrdersSidebar';
import GaMissionPlanningAccommodationFilter from '@views/operationnel/accommodationplanning/filter/GaMissionPlanningAccommodationFilter';
import GaReservationLitigeSidebar from '@views/reservation/sidebar/GaReservationLitigeSidebar';
import GaReservationReclamationSidebar from '@views/reservation/sidebar/GaReservationReclamationSidebar';
import FacturationHoteOperationsSidebarFilter from '@views/accounting/facturationhote2/operations/filter/FacturationHoteOperationsSidebarFilter';
import FacturationHoteConsultationSidebarFilter from '@views/accounting/facturationhote2/consultation/filter/FacturationHoteConsultationSidebarFilter';
import FacturationVoyageurSidebarFilter from '@views/accounting/facturationvoyageur/filter/FacturationVoyageurSidebarFilter';
import GaAccommodationMandatUpdateSidebar from '@views/accommodation/mandat/sidebar/GaAccommodationMandatUpdateSidebar.vue';
import GaAccommodationReservationFilterList from '@views/reservation/filter/GaAccommodationReservationFilterList.vue';
import GaCommercialFilterList from '@views/commercials/filter/GaCommercialFilterList.vue';
import GaContactDetailsSidebar from '@views/contacts/sidebar/GaContactDetailsSidebar.vue';

Date.prototype.toJSON = function () {
  return moment(this).utc(true).format();
};

const i18n = createI18n({
  locale: navigator.language,
  fallbackLocale: 'fr',
  fallbackWarn: false,
  missingWarn: false,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages: {
    fr: fr,
    en: en,
  },
});

moment.locale('fr');
const pinia = createPinia();
const appVue = createApp(App)
  .use(store)
  .use(pinia)
  .use(i18n)
  .use(createMetaManager())
  .use(vueMetaPlugin)
  .use(router)
  .use(FloatingVue)
  .use(LoadingPlugin)
  .use(VueLoaders)
  .use(VSwatches)
  .use(VueSmoothScroll)
  .use(PerfectScrollbar)
  .use(VuelidatePlugin)
  .use(VueMobileDetection)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_MAPS_KEY,
      language: 'fr',
      v: 'weekly',
      libraries: 'places,marker',
    },
  })
  .use(PrimeVue, {
    locale: {
      dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
      monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      monthNamesShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
      today: "Aujourd'hui",
      weekHeader: 'Wk',
      firstDayOfWeek: 1,
      clear: 'Effacer',
    },
  })
  .use(ConfirmationService);

appVue.component('PDialog', Dialog);
appVue.component('DataTable', DataTable);
appVue.component('Message', Message);
appVue.component('Column', Column);
appVue.component('Sidebar', Sidebar);
appVue.component('DataView', DataView);
appVue.component('GaView', GaView);
appVue.component('GaContentView', GaContentView);
appVue.component('GaTabView', GaTabView);
appVue.component('GaTitleMenu', GaTitleMenu);
appVue.component('GaItemTitle', GaItemTitle);
appVue.component('GaContactSidebar', GaContactSidebar);
appVue.component('GaReservationSidebar', GaReservationSidebar);
appVue.component('GaCommentSidebar', GaCommentSidebar);
appVue.component('GaReservationFilterPlanning', GaReservationFilterPlanning);
appVue.component('GaReservationFilterList', GaReservationFilterList);
appVue.component('GaAccommodationFilterList', GaAccommodationFilterList);
appVue.component('FacturationHoteOperationsSidebarFilter', FacturationHoteOperationsSidebarFilter);
appVue.component('FacturationHoteConsultationSidebarFilter', FacturationHoteConsultationSidebarFilter);
appVue.component('FacturationVoyageurSidebarFilter', FacturationVoyageurSidebarFilter);
appVue.component('GaOperationsConsultationFilterList', GaOperationsConsultationFilterList);
appVue.component('GaPaymentFilterList', GaPaymentFilterList);
appVue.component('GaThirdPartyAccountFilterList', GaThirdPartyAccountFilterList);
appVue.component('GaRecapitulatifFacturationFilterList', GaRecapitulatifFacturationFilterList);
appVue.component('GaAccountFilterList', GaAccountFilterList);
appVue.component('GaTransactionsFilterList', GaTransactionsFilterList);
appVue.component('GaPartenaireFilterList', GaPartenaireFilterList);
appVue.component('GaHousekeeperFilterList', GaHousekeeperFilterList);
appVue.component('GaPrestataireFilterList', GaPrestataireFilterList);
appVue.component('GaProspectFilterList', GaProspectFilterList);
appVue.component('GaOperationsOwnerChargeFilter', GaOperationsOwnerChargeFilter);
appVue.component('GaCrgConsultationFilter', GaCrgConsultationFilter);
appVue.component('GaCrgListFilter', GaCrgListFilter);
appVue.component('GaCrgPaymentFilter', GaCrgPaymentFilter);
appVue.component('GaCrgHistoriqueFilter', GaCrgHistoriqueFilter);
appVue.component('GaOwnerListFilter', GaOwnerListFilter);
appVue.component('GaClientListFilter', GaClientListFilter);
appVue.component('GaTicketListFilter', GaTicketListFilter);
appVue.component('GaBalanceFilter', GaBalanceFilter);
appVue.component('GaStayRateConsultationFilter', GaStayRateConsultationFilter);
appVue.component('GaStayRateTransfertFilter', GaStayRateTransfertFilter);
appVue.component('GaCancelFilter', GaCancelFilter);
appVue.component('GaEmployeeListFilter', GaEmployeeListFilter);
appVue.component('GaParametrageCommissionFilter', GaParametrageCommissionFilter);
appVue.component('GaLitigeListFilter', GaLitigeListFilter);
appVue.component('GaOwnerCancelListFilter', GaOwnerCancelListFilter);
appVue.component('GaMandatListFilter', GaMandatListFilter);
appVue.component('GaAvenantListFilter', GaAvenantListFilter);
appVue.component('GaMaintenanceListFilter', GaMaintenanceListFilter);
appVue.component('GaUpsellListFilter', GaUpsellListFilter);
appVue.component('GaMissionPlanningParticipantFilter', GaMissionPlanningParticipantFilter);
appVue.component('GaMissionSuiviFilter', GaMissionSuiviFilter);
appVue.component('GaWaitingOrdersFilter', GaWaitingOrdersFilter);
appVue.component('GaAccommodationExtrasFilter', GaAccommodationExtrasFilter);
appVue.component('GaMissionDashboardFilter', GaMissionDashboardFilter);
appVue.component('GaReviewListFilter', GaReviewListFilter);
appVue.component('GaReviewSidebar', GaReviewSidebar);
appVue.component('GaAccommodationReviewsFilter', GaAccommodationReviewsFilter);
appVue.component('GaPrestationFilter', GaPrestationFilter);
appVue.component('GaMissionSuiviPartnerFilter', GaMissionSuiviPartnerFilter);
appVue.component('GaMissionOrderListFilter', GaMissionOrderListFilter);
appVue.component('GaCrgOwnerFilter', GaCrgOwnerFilter);
appVue.component('GaMissionWaitingOrdersSidebar', GaMissionWaitingOrdersSidebar);
appVue.component('GaMissionPlanningAccommodationFilter', GaMissionPlanningAccommodationFilter);
appVue.component('GaReservationLitigeSidebar', GaReservationLitigeSidebar);
appVue.component('GaReservationReclamationSidebar', GaReservationReclamationSidebar);
appVue.component('GaAccommodationMandatUpdateSidebar', GaAccommodationMandatUpdateSidebar);
appVue.component('GaAccommodationReservationFilterList', GaAccommodationReservationFilterList);
appVue.component('GaCommercialFilterList', GaCommercialFilterList);
appVue.component('GaContactDetailsSidebar', GaContactDetailsSidebar);

appVue.directive('tooltip', Tooltip);
appVue.directive('badge', BadgeDirective);

appVue.mount('#app');
appVue.config.unwrapInjectedRef = true;
